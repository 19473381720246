import { createStore } from 'vuex'
import appGlobal from "@/store/modules/appGlobal";
import VueCookies from 'vue-cookies'

export default createStore({
  state: {
    //驱动应用的数据源
    userId: '' || VueCookies.get('userId'),
    isBusiness:false,
    isManager:false,
    procedure:{
      id:undefined,
      missionDatas:undefined,
      steps:undefined,
      active:undefined,
      mission:undefined,
    }
  },
  getters: {
    username: (state) => state.username,
    userId: (state) => state.userId,
    isBusiness: (state) => state.isBusiness,
    isManager: (state) => state.isManager
  },
  mutations: {
    login: (state, loginInfo) => {
      state.userId = loginInfo.userId;
      state.isManager = loginInfo.isManager;
      state.isBusiness = loginInfo.isBusiness;
      debugger
      console.log('登录信息：',loginInfo)
      // 把登录的用户的名保存到localStorage中，防止页面刷新，导致vuex重新启动，用户名就成为初始值（初始值为空）的情况
      VueCookies.set("userId",loginInfo.userId,loginInfo.time/1000);
      VueCookies.set("Authorization",loginInfo.token,loginInfo.time/1000);
      console.log('存入cookies的用户名：',VueCookies.get("Authorization"))
      console.log('存入cookies的密码：',VueCookies.get("userId"))
    },
    setManager(state, isManager){
      state.isManager = isManager;
    },
    logout:(state) =>{
      state.userId = null;
      VueCookies.remove('userId');
      VueCookies.remove("Authorization");
    }

  },
  actions: {
  },
  modules: {
    /**
     * app全局参数
     */
    appGlobal,
  }
})
