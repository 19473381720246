const methods = {
    format(date, fmt){
        var a = ['日', '一', '二', '三', '四', '五', '六']
        var o = {
            'M+': date.getMonth() + 1, // 月份
            'd+': date.getDate(), // 日
            'h+': date.getHours(), // 小时
            'm+': date.getMinutes(), // 分
            's+': date.getSeconds(), // 秒
            'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
            'S': date.getMilliseconds(), // 毫秒
            'w': date.getDay(), // 周
            'W': a[date.getDay()], // 大写周
            'T': 'T'
        }
        if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substring(4 - RegExp.$1.length)) }
        for (var k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
        }
        return fmt
    },

    formatStr(dateStr, fmt){
        let date = new Date(dateStr);
        return this.format(date,fmt);
    },

    getFullYear(date){
        return date.getFullYear();
    },

    getFullYearStr(dateStr){
        let date = new Date(dateStr);
        return this.getFullYear(date);
    },

    getMonth(date){
        return date.getMonth() + 1;
    },

    getMonthStr(dateStr){
        let date = new Date(dateStr);
        return this.getMonth(date);
    },

    getDate(date,preZero){
        return date.getDate()<10&&preZero?("0"+date.getDate()):date.getDate()
    },

    getDateStr(dateStr,preZero){
        let date = new Date(dateStr);
        return this.getDate(date,preZero);
    }

}

export default{

    /**
     * Vue.use调用函数
     * @param {*} app 
     */
    install:(app) =>{
        app.config.globalProperties.$dateUtil = methods;
    }

}