export default[
    {
        path: "userManage",
        name: "UserManage",
        component: () => import("@/views/account/UserManage.vue")
    },
    {
        path: "roleManage",
        name: "RoleManage",
        component: () => import("@/views/account/RoleManage.vue")
    },
    {
        path: "authorizeManage",
        name: "AuthorizeManage",
        component: () => import("@/views/account/AuthorizeManage.vue")
    }
]