import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Home from '../views/Home.vue'
import accountModule from './modules/account'
import bbsModule from './modules/bbs'
import bbsBackModule from './modules/bbsBack'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "about" */ '../views/index/Index.vue'),
    children:[

      {
        path: '/',
        name: 'Show',
        component: () => import(/* webpackChunkName: "about" */ '../views/index/Show.vue'),
      },
      {
        path: '/indextrainingApplication',
        name: 'IndexTrainingApplication',
        component: () => import(/* webpackChunkName: "about" */ '../views/TrainingApplication/trainingApplication'),
      },
      {
        path: '/frequirementShow',
        name: 'FRequirementShow',
        component: () => import(/* webpackChunkName: "about" */ '../views/requirement/frequirementShow.vue'),
      },
      {
        path: '/fnewsList',
        name: 'FNewsList',
        component: () => import(/* webpackChunkName: "about" */ '../views/news/NewsList.vue'),
      },

      {
        path: '/fannounceList',
        name: 'FAnnounceList',
        component: () => import(/* webpackChunkName: "about" */ '../views/announcement/Announcement.vue'),
      },
      {
        path: '/fassessList',
        name: 'FAssessList',
        component: () => import(/* webpackChunkName: "about" */ '../views/assessment/AssessList.vue'),
      },
      {
        path: '/fnewsShow',
        name: 'FNewsShow',
        component: () => import(/* webpackChunkName: "about" */ '../views/news/NewsShow.vue'),
      },
      {
        path: '/fsmartRec',
        name: 'FsmartRec',
        component: () => import(/* webpackChunkName: "about" */ '../views/smartRec/smartRec.vue'),
      },
      {
        path: '/fannounceShow',
        name: 'FAnnounceShow',
        component: () => import(/* webpackChunkName: "about" */ '../views/announcement/AnnounceShow.vue'),
      },


      // {
      //   path: '/newsList',
      //   name: 'NewsList',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/index/NewsList.vue'),
      // },
      // {
      //   path: '/myAchievement',
      //   name: 'MyAchievement',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/index/MyAchievement.vue'),
      // },
      // {
      //   path: '/achievementCheck',
      //   name: 'AchievementCheck',
      //   component: () => import(/* webpackChunkName: "about" */ '../views/index/AchievementCheck.vue'),
      // },
      // {
      //   path: '/achievementEdit',
      //   name: 'AchievementEdit',
      //   component: () => import(/* webpackChunkName: "about"*/ '../views/index/AchievementEdit.vue'),
      // },
      // {
      //   path: '/achievementShow',
      //   name: 'AchievementShow',
      //   component: () => import(/* webpackChunkName: "about"*/ '../views/index/AchievementShow.vue'),
      // },
      {
        path: '/fachievementList',
        name: 'FAchievementList',
        component: () => import(/* webpackChunkName: "about"*/ '../views/achievement/AchievementList.vue'),
      },
      {
        path: '/fachievementShow',
        name: 'FAchievementShow',
        component: () => import(/* webpackChunkName: "about"*/ '../views/achievement/AchievementShow.vue'),
      },
      {
        path: '/fassessShow',
        name: 'FAssessShow',
        component: () => import(/* webpackChunkName: "about"*/ '../views/assessment/AssessShow.vue'),
      },
      // {
      //   path: '/achievementShowCheck',
      //   name: 'AchievementShowCheck',
      //   component: () => import(/* webpackChunkName: "about"*/ '../views/index/AchievementShowCheck.vue'),
      // },
      {
        path: '/farRequirement',
        name: 'FarRequirement',
        component: () => import(/* webpackChunkName: "about"*/ '../views/requirement/ArRequirement.vue'),
      },
      {
        path: '/agricultureStarList',
        name: 'FgricultureStarList',
        component: () => import(/* webpackChunkName: "about"*/ '../views/FileUpload/agricultrueStarList.vue'),
      },
      {
        path: '/indexVedioDetails',
        name: 'IndexVedioDetails',
        component: () => import(/* webpackChunkName: "about"*/ '../views/FileUpload/FileDetailsShow'),
      },
      {
        path: '/fileDownload',
        name: 'FileDownload',
        component: () => import(/* webpackChunkName: "about"*/ '../views/FileUpload/fileDownload'),
      },
    ]
  },
  {
    path: '/Home',
    name: 'Home',
    meta: {auth: true},
    component: Home,
    children:[
      // trainingApplication
      {
        path: '/fileRevise',
        name: 'FileRevise',
        meta: {auth: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/FileUpload/FileRevise')
      },
      {
        path: '/trainingApplication',
        name: 'TrainingApplication',
        meta: {auth: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/TrainingApplication/trainingApplication')
      },
      {
        path: '/fileEdit',
        name: 'FileEdit',
        meta: {auth: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/FileUpload/FileEdit')
      },
      {
        path: '/first',
        name: 'First',
        meta: {auth: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/First.vue')
      },{
        path: '/fileDetailsShow',
        name: 'FileDetailsShow',
        component: () => import(/* webpackChunkName: "about"*/ '../views/FileUpload/FileDetailsShow'),
      },
      {
        path: '/fileKindList',
        name: 'FileKindList',
        meta: {auth: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/FileUpload/FileKindList')
      },
      {
        path: '/wordUploadEdit',
        name: 'WordUploadEdit',
        meta: {auth: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/FileUpload/wordUploadEdit')
      },
      {
        path: '/uploadNews',
        name: 'UploadNews',
        component: () => import(/* webpackChunkName: "about" */ '../views/news/UploadNews.vue'),
      },
      {
        path: '/uploadAnnounce',
        name: 'UploadAnnounce',
        component: () => import(/* webpackChunkName: "about" */ '../views/announcement/UploadAnnounce.vue'),
      },
      {
        path: '/uploadAssess',
        name: 'UploadAssess',
        component: () => import(/* webpackChunkName: "about" */ '../views/assessment/UploadAssess.vue'),
      },
      {
        path: '/uploadCompany',
        name: 'UploadCompany',
        component: () => import(/* webpackChunkName: "about" */ '../views/company/UploadCompany.vue'),
      },
      {
        path: '/newsList',
        name: 'NewsList',
        component: () => import(/* webpackChunkName: "about" */ '../views/news/NewsList.vue'),
      },
      {
        path: '/companyList',
        name: 'CompanyList',
        component: () => import(/* webpackChunkName: "about" */ '../views/company/CompanyList.vue'),
      },
      {
        path: '/arRequirement',
        name: 'ArRequirement',
        component: () => import(/* webpackChunkName: "about" */ '../views/requirement/ArRequirement.vue'),
      },
      {
        path: '/announceList',
        name: 'AnnounceList',
        component: () => import(/* webpackChunkName: "about" */ '../views/announcement/Announcement.vue'),
      },
      {
        path: '/assessList',
        name: 'AssessList',
        component: () => import(/* webpackChunkName: "about" */ '../views/assessment/AssessList.vue'),
      },
      {
        path: '/myAchievement',
        name: 'MyAchievement',
        component: () => import(/* webpackChunkName: "about" */ '../views/achievement/MyAchievement.vue'),
      },
      {
        path: '/myRequirement',
        name: 'MyRequirement',
        component: () => import(/* webpackChunkName: "about" */ '../views/requirement/MyRequirement.vue'),
      },
      {
        path: '/myassess',
        name: 'MyAssess',
        component: () => import(/* webpackChunkName: "about" */ '../views/assessment/MyAssess.vue'),
      },
      {
        path: '/requirementEdit',
        name: 'RequirementEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/requirement/requirementEdit.vue'),
      },
      {
        path: '/myAnnouncement',
        name: 'MyAnnouncement',
        component: () => import(/* webpackChunkName: "about" */ '../views/announcement/MyAnnouncement.vue'),
      },

      {
        path: '/myCompany',
        name: 'MyCompany',
        component: () => import(/* webpackChunkName: "about" */ '../views/company/MyCompany.vue'),
      },
      {
        path: '/achievementEdit',
        name: 'AchievementEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/achievement/AchievementEdit.vue'),
      },
      {
        path: '/achievementCheck',
        name: 'AchievementCheck',
        component: () => import(/* webpackChunkName: "about" */ '../views/achievement/AchievementCheck.vue'),
      },
      {
        path: '/assessCheck',
        name: 'AssessCheck',
        component: () => import(/* webpackChunkName: "about" */ '../views/assessment/AssessCheck.vue'),
      },
      {
        path: '/requirementCheck',
        name: 'RequirementCheck',
        component: () => import(/* webpackChunkName: "about" */ '../views/requirement/RequirementCheck.vue'),
      },
      {
        path: '/achievementShowCheck',
        name: 'AchievementShowCheck',
        component: () => import(/* webpackChunkName: "about"*/ '../views/achievement/AchievementShowCheck.vue'),
      },
      {
        path: '/assessShowCheck',
        name: 'AssessShowCheck',
        component: () => import(/* webpackChunkName: "about"*/ '../views/assessment/AssessShowCheck.vue'),
      },
      {
        path: '/requirementShowCheck',
        name: 'RequirementShowCheck',
        component: () => import(/* webpackChunkName: "about"*/ '../views/requirement/RequirementShowCheck.vue'),
      },
      {
        path: '/achievementList',
        name: 'AchievementList',
        component: () => import(/* webpackChunkName: "about"*/ '../views/achievement/AchievementList.vue'),
      },
      {
        path: '/newsShow',
        name: 'NewsShow',
        component: () => import(/* webpackChunkName: "about" */ '../views/news/NewsShow.vue'),
      },
      {
        path: '/requirementShow',
        name: 'RequirementShow',
        component: () => import(/* webpackChunkName: "about" */ '../views/requirement/RequirementShow.vue'),
      },
      {
        path: '/announceShow',
        name: 'AnnounceShow',
        component: () => import(/* webpackChunkName: "about" */ '../views/announcement/AnnounceShow.vue'),
      },
      {
        path: '/assessShow',
        name: 'AssessShow',
        component: () => import(/* webpackChunkName: "about" */ '../views/assessment/MyAssessShow.vue'),
      },
      {
        path: '/companyShow',
        name: 'CompanyShow',
        component: () => import(/* webpackChunkName: "about" */ '../views/company/CompanyShow.vue'),
      },
      {
        path: '/achievementShow',
        name: 'AchievementShow',
        component: () => import(/* webpackChunkName: "about"*/ '../views/achievement/AchievementShow.vue'),
      },
      {
        path: '/myNews',
        name: 'MyNews',
        meta: {auth: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/news/MyNews.vue')
      },
      {
        path: '/myNewsCheck',
        name: 'MyNewsCheck',
        meta: {auth: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/news/MyNewsCheck.vue')
      },
      {
        path: '/newsShowCheck',
        name: 'NewsShowCheck',
        meta: {auth: true},
        component: () => import(/* webpackChunkName: "about" */ '../views/news/NewsShowCheck.vue')
      },
      ...accountModule,
      ...bbsBackModule
    ]
  },

  ...bbsModule
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
