import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios/axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@/ckeditor/ckeditor.js';
import VueCookies from 'vue-cookies'
import {ElMessage } from 'element-plus'
import './reset.css'
import dataFormat from './util/dataFormat'
import dateUtil from './util/dateUtil'
import Check from './util/check'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

window.ClassicEditor = ClassicEditor

const app = createApp(App)
app.use(store).use(router).use(axios).use(ElementPlus).use(CKEditor).use(VueCookies).use(Check).use(dataFormat).use(dateUtil);

app.config.globalProperties.$msg = ElMessage; //配置全局对信息提示
app.config.globalProperties.$check = Check
app.config.globalProperties.$cookies = VueCookies

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')