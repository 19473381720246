export default[
    {
        path: "carouselManage",
        name: "CarouselManage",
        component: () => import("@/views/bbsBack/CarouselManage.vue")
    },
    {
        path: "noticeManage",
        name: "NoticeManage",
        component: () => import("@/views/bbsBack/NoticeManage.vue")
    },
    {
        path: "areaManage",
        name: "AreaManage",
        component: () => import("@/views/bbsBack/AreaManage.vue")
    },
    {
        path: "categoryManage",
        name: "CategoryManage",
        component: () => import("@/views/bbsBack/CategoryManage.vue")
    },
    {
        path: "tagManage",
        name: "TagManage",
        component: () => import("@/views/bbsBack/TagManage.vue")
    },
    {
        path: "topicManage",
        name: "TopicManage",
        component: () => import("@/views/bbsBack/TopicManage.vue")
    },
    {
        path: "banManage",
        name: "BanManage",
        component: () => import("@/views/bbsBack/BanManage.vue")
    }
]