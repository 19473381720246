export default[
    {
        path: '/bbs',
        name: 'BBS',
        meta: { title: "雄君论坛" },
        component: () => import("@/views/bbs/HomePage")
      },
      {
        path: "/bbs/login",
        name: "BBSLogin",
        meta: { title: "雄君论坛" },
        component: () => import("@/views/bbs/Login.vue")
      },
      {
        path: "/bbs/register",
        name: "BBSRegister",
        meta: { title: "雄君论坛" },
        component: () => import("@/views/bbs/Register.vue")
      },
      {
        path: "/bbs/forgetPassword",
        name: "BBSForgetPwd",
        meta: { title: "雄君论坛" },
        component: () => import("@/views/bbs/ForgetPassword.vue")
      },
      {
        path: "/resetPassword",
        name: "ResetPassword",
        meta: { title: "兵棋论坛" },
        component: () => import("@/views/bbs/page/ResetPassword")
      },
      {
        path: "/bbs/notice",
        name: "Notice",
        meta: { title: "雄君论坛" },
        component: () => import("@/views/bbs/page/Notice")
      },
      {
        path: "/bbs/noticeDetail",
        name: "NoticeDetail",
        meta: { title: "雄君论坛" },
        component: () => import("@/views/bbs/page/NoticeDetail")
      },
      {
        path: "/bbs/myTopic",
        name: "MyTopic",
        meta: { title: "雄君论坛" },
        component: () => import("@/views/bbs/page/MyTopic")
      },
      {
        path: "/bbs/myCollection",
        name: "MyCollection",
        meta: { title: "兵棋论坛" },
        component: () => import("@/views/bbs/page/MyCollection")
      },
      {
        path: "/bbs/dynamics",
        name: "Dynamics",
        meta: { title: "兵棋论坛" },
        component: () => import("@/views/bbs/page/Dynamics")
      },
      {
        path: "/bbs/searchNotes",
        name: "SearchNotes",
        meta: { title: "兵棋论坛" },
        component: () => import("@/views/bbs/page/SearchNotes")
      },
      {
        path: "/bbs/category",
        name: "Category",
        meta: { title: "兵棋论坛" },
        component: () => import("@/views/bbs/page/Category.vue")
      },
      {
        path: "/bbs/topic",
        name: "Topic",
        meta: { title: "兵棋论坛" },
        component: () => import("@/views/bbs/page/Topic.vue")
      },
      {
        path: "/bbs/modifyUserInfo",
        name: "ModifyUserInfo",
        meta: { title: "兵棋论坛" },
        component: () => import("@/views/bbs/page/ModifyUserInfo")
      }
]